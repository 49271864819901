import React from 'react'

import Header from './header/header'
import Footer from './footer/footer'
import '../styles/index.scss'

const MainLayout = (props) => {
  return (
    <div className="main-layout">
      <div className="content">
        <Header />
        {props.children}
      </div>
      <Footer />
    </div>
  )
}

export default MainLayout
