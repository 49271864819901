import React, {useState} from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby';


import SideDrawer from '../side_drawer/sideDrawer';
import styles from './header.module.scss';
import NavLinks from '../navLinks';

const Header = () => {
  const data = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
  `)


 
  const siteLogo = "//images.ctfassets.net/rbvlyhbn10d9/2Z3Gtl4zhswpumnrVsTFMT/14416ba8290c550390dd6410835d15bf/socialProfil.png"
  const [sideDrawer, sideDrawerToggle] = useState(false); // burger menu toogle
  let menuStatus = 'side-drawer'
  if (sideDrawer) {
    menuStatus = 'side-drawer open'
  }

  // const NavLinks = () => {
  //   return (
  //     <ul>
  //       <li><Link activeClassName={styles.activeNavItem} to="/"> Home </Link></li>
  //       <li><Link activeClassName={styles.activeNavItem} to="/projects"> Projects </Link></li>
  //       <li><Link activeClassName={styles.activeNavItem} to="/blog"> Blog </Link></li>
  //       <li><Link activeClassName={styles.activeNavItem} to="/about"> About </Link></li>
  //       <li><Link activeClassName={styles.activeNavItem} to="/contact"> Contact </Link></li>
  //     </ul>
  //   )
  // }
  

  return (
    <header className={`container-full ${styles.header}`}>
      <div className={`${styles.headerInner}`}>
        <div className={`${styles.headerLogo}`}>
        <Link to="/"><img className={`${styles.headerLogo}`} src={siteLogo} alt="site logo"/></Link>
        </div>
        <Link to="/">
        <p>USMAN KIYANI</p>
         </Link>
        <nav>
        <div className={styles.navInner}>
         <NavLinks />

          <button onClick={() => sideDrawerToggle(sideDrawer ==! sideDrawerToggle)} className={`${styles.toggleButton} ${menuStatus}`} >
            <div className={`${styles.toggleButtonLine} toggle-botton-line-1`} />
            <div className={`${styles.toggleButtonLine} toggle-botton-line-2`} />
            <div className={`${styles.toggleButtonLine} toggle-botton-line-3`} />
          </button>
        </div>
      </nav>
      </div>
      
      <SideDrawer menuStatus={menuStatus}>
        <NavLinks />
      </SideDrawer>
    </header>
  )
}

export default Header
