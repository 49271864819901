import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby';


import styles from './sideDrawer.module.scss';


const SideDrawer = ({menuStatus, children}) => {
  return (
    <nav className={`${menuStatus + ' ' + styles.sideDrawer}`}>
     {children}
     <p className={styles.contact}> <a href="mailto:uskiyani@gmail.com">Uskiyani@gmail.com</a></p>
    </nav>
  )
}

export default SideDrawer
