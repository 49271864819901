import React from 'react'
import { graphql, useStaticQuery} from 'gatsby';

import NavLinks from '../navLinks';
import styles from './footer.module.scss';

const Footer = () => {
  const data = useStaticQuery(graphql`
  query {
    site {
      siteMetadata{
        author
      }
    }
  }
  `)

  var d = new Date();
  var n = d.getFullYear();
  const siteLogo = "//images.ctfassets.net/rbvlyhbn10d9/2Z3Gtl4zhswpumnrVsTFMT/14416ba8290c550390dd6410835d15bf/socialProfil.png"

  
  return (
    <footer className={styles.footer}>
      <div className={`${styles.footerItem} ${styles.footerBrand}`}> 
          <div className={styles.logo}>
           <img className={styles.logoImg} src={siteLogo} alt="sitelogo"/>  
          </div>
          <h4>USMAN KIYANI</h4>
      </div>
      <div className={styles.footerItem}><NavLinks /></div>
      <div className={`${styles.footerItem} ${styles.footerContact}`}> <a href="mailto:uskiyani@gmail.com">Uskiyani@gmail.com</a></div>
      <div className={`${styles.footerItem} ${styles.footerRights}`}>Copyright &copy; {n} All Rights Reserved </div>
    </footer>
  )
}

export default Footer
