import { Link } from 'gatsby';
import styles from './header/header.module.scss';
import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'





const NavLinks = () => {

  const [copyStatus, setStatus] = useState('Click & Copy Email');
  const [copyMailStatus, setMailStatus] = useState('');



  return (
    <ul>
      <li><Link activeClassName={styles.activeNavItem} to="/"> Home </Link></li>
      <li><Link activeClassName={styles.activeNavItem} to="/projects"> Projects </Link></li>
      {/* <li><Link activeClassName={styles.activeNavItem} to="/blog"> Blog </Link></li> */}
      {/* <li><Link activeClassName={styles.activeNavItem} to="/about"> About </Link></li> */}
      <li>
   <a href="mailto:uskiyani@gmail.com">Contact</a>
         </li>
    </ul>
  )
}

export default NavLinks;